import { Fragment } from 'react';
import './App.css';
import { useEffect, useState } from 'react';
import Airtable from 'airtable';
import { PieChart } from 'react-minimal-pie-chart';
import moment from "moment";

function App() {
  const [pushups, setPushups] = useState([])
  const [totalPushups, setTotalPushups] = useState(0)
  const [max, setMax] =  useState(0)
  const goal = 30000

  useEffect(() => {
    let base = null
    base = new Airtable({apiKey: process.env.REACT_APP_APIKEY}).base(process.env.REACT_APP_BASEID);
    
    const temp = []

    base("push_ups").select({
        view: 'Grid view' // Or any other view you want to use
      }).eachPage((records, fetchNextPage) => {
        records.forEach((record) => {
          temp.push({
            amount: record.fields.amount,
            date: new Date(record.fields.date)
          })
        }, (err) => {
          if (err) {
            console.error(err);
            return;
          }
        })
        fetchNextPage()
      }).then(() => {
        setPushups(temp)
      })
  }, [])

  useEffect(() => {
    let total = 0
    let temp = 0
    pushups.forEach(entry => {
      if (entry.amount > temp) {temp = entry.amount}
      total += entry.amount
    })
    setMax(temp)
    setTotalPushups(total)
  }, [pushups])

  const remainingAverage = () => {
    let today = new Date() 
    let end = new Date("12/31/2025")

    return Math.round((goal - totalPushups) / Math.round((end - today) / (1000 *  3600 * 24)))
  }
  
  const today = () => {

    let total = 0
    pushups.forEach((pushup) => {
      const pushupDate = moment(pushup.date)
      const now = moment()
      if (pushupDate.isSame(now, 'day')) {
        total += pushup.amount
      }
    })

    return total
  }

  return (
    <div className="App w-full">
      <img alt='vaprowave' src='assets/images/vaporwave.png' className='absolute top-0 left-0 w-16 lg:w-32' />
      <img alt='paulys hip website!!!' src="assets/images/header.png" className='m-auto w-3/4'/>
      <div className='relative mx-4 h-full lg:mx-auto'>
        <div 
          className='absolute w-32 h-full left-0 hidden lg:block' 
          style={{backgroundImage: `url("/assets/images/background.jpg")`, backgroundSize: 'contain'}} 
        />
        <div 
          className='absolute w-32 h-full right-0 hidden lg:block' 
          style={{backgroundImage: `url("/assets/images/background.jpg")`, backgroundSize: 'contain'}} 
        />
        <div className='lg:w-1/2 mx-auto'>
          <img alt='im survivor boy!' src='assets/images/survivorboy.jpg'/>
          <p className='mt-4 text-xl font-semibold text-wrap'>
            My name's Chris Cattron, and I'm....Survivor Boy! Just kidding! My name is Pauly Cassell, and
            the picture above this is one of my best friends, Chris. He's a super funny, kind, and caring friend,
            and I'm lucky to have him as part of my life! While he may be subpar at things like peggle and jeopardy,
            he's a top notch Halo player. But regardless of his skill (or therefore lackof) in any video game,
            he's an all around fun person to be around!
          </p>
        </div>

      </div>
      
      <div className='flex flex-col-reverse m-8 lg:flex-row lg:align-top lg:justify-start'>
        <div className='table border border-black lg:mr-8'>
          <h3 className='text-xl font-bold border-b border-black'>Push-up Counter for 2025</h3>
          <div className='grid grid-cols-4'>
            <h4 className='text-lg border-r border-b border-black font-semibold col-span-1'>Amount</h4>
            <h4 className='text-lg border-b border-black font-semibold col-span-3'>Date/Time</h4>
          </div>
          <div className='hidden-scrollbar grid grid-cols-4 max-h-64 overflow-scroll'>
          {pushups.length > 0 && pushups.map((entry, index) => (
              <Fragment key={index}>
                <span className='border-r border-black col-span-1'>{entry.amount}</span>
                <span className='px-2 col-span-3 text-nowrap text-left mx-2'>{String(moment(entry.date).format('MM/DD/YYYY hh:mm:ss a'))}</span>
              </Fragment>
            )).reverse()}
          </div>
        </div>
        <div className='mb-8 lg:mr-8 lg:mb-0'>
          <div className='table border border-black flex-none'>
            <div className='grid grid-cols-3'>
              <h4 className='text-lg border-r border-b border-black px-3 font-semibold'>Amount per day needed</h4>
              <h4 className='text-lg border-r  border-b border-black font-semibold'>Most at one time</h4>
              <h4 className='text-lg border-b border-black font-semibold'>Amount done today</h4>
              <span className='border-r border-black'>{remainingAverage()}</span>
              <span className='border-r border-black'>{max}</span>
              <span className='border-black'>{today()}</span>
            </div>
          </div>
        </div>
        <div className='mb-8 lg:mb-0'>
          <div className='table border border-black flex-none'>
            <div className='grid grid-cols-3'>
              <h4 className='text-lg border-r border-b border-black px-3 font-semibold'>Total Push-ups</h4>
              <h4 className='text-lg border-r border-b border-black font-semibold'>Goal</h4>
              <h4 className='text-lg border-b border-black font-semibold'>Remaining</h4>
              <span className='border-r border-black'>{totalPushups}</span>
              <span className='border-r border-black'>{goal}</span>
              <span>{goal - totalPushups}</span>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col-reverse m-8 lg:flex-row lg:align-top lg:justify-start'>
        <div className='flex'>
          <PieChart 
            className='w-1/3 mr-3 lg:mr-0 lg:w-64'
            data={[
              {title: 'Push ups done', value: totalPushups, color: '#00FFFF'},
              {title: 'Push ups NOT done', value: (goal - totalPushups), color: '#EC00FF'}]}
          />
          <div className='ml-0 lg:ml-4'>
            <div className='flex'>
              <div className='lg:w-6 lg:h-6 w-4 h-4 border border-black mr-2' style={{ backgroundColor: "#00FFFF"}} />
              <span className='text-sm lg:text-base'>Push ups done</span>
            </div>
            <div className='flex mt-4'>
              <div className='lg:w-6 lg:h-6 w-4 h-4 border border-black mr-2' style={{ backgroundColor: "#EC00FF"}} />
              <span className='text-sm lg:text-base'>Push ups NOT done</span>
            </div>
          </div>
        </div>
      </div>

      {/* <PieChart data={[{title: 'Push ups done', value: 1, color: '#E38627'}]}  /> */}
    </div>
  );
}

export default App;
